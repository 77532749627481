<template>
  <section>
      <div class="content-header">
        <h2>Providing quality feedback  </h2>
      </div>
      <div class="content-wrapper">
        <p>These two articles describe how the process of giving student feedback can support academic buoyancy. </p>
        <ul>
          <li><a href="http://eprints.glos.ac.uk/7033/" target="_blank">Developing academic buoyancy and resilience through the assessment feedback process</a></li>
          <li><a href="https://www.tandfonline.com/doi/abs/10.1080/02602938.2017.1356265?journalCode=caeh20" target="_blank">The role of assessment feedback in developing academic buoyancy</a></li>
        </ul>
        <p>And <a href="https://www.insidehighered.com/news/2019/01/14/can-light-touch-targeted-feedback-students-improve-their-perceptions-and-performance?utm_source=Inside+Higher+Ed&utm_campaign=b3d4553c60-DNU_2019_COPY_01&utm_medium=email&utm_term=0_1fcbc04421-b3d4553c60-197744637&mc_cid=b3d4553c60&mc_eid=732401f975" target="_blank">My Professor Cares</a> describes how light-touch, targeted feedback by way of emails to the class can help students do better in class.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
